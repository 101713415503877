<template>
    <div>
        <b-tabs content-class="pt-5 position-relative" class="line-tabs" v-model="tabIndex">
            <div class="tabs-bottom-line"></div>
            <ValidationObserver ref="formModalValidate">
                <b-tab :title="$t('staff_information')" active ref="tab1">
                    <b-row class="mt-2">
                        <b-col cols="12" md="4">
                            <ValidationProvider name="username"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('username')">
                                    <b-form-input v-model="formData.username"
                                                  :placeholder="$t('username')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <!--                            <ValidationProvider name="registration_number"-->
                            <!--                                                rules="required"-->
                            <!--                                                v-slot="{ valid, errors }">-->
                            <!--                                <b-form-group :label="$t('registration_number')">-->
                            <!--                                    <b-form-input type="text"-->
                            <!--                                                  v-model="formData.registration_number"-->
                            <!--                                                  :placeholder="$t('registration_number')"-->
                            <!--                                                  :state="errors[0] ? false : null"/>-->
                            <!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
                            <!--                                </b-form-group>-->
                            <!--                            </ValidationProvider>-->
                            <ValidationProvider name="title"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('title1')">
                                    <parameter-selectbox v-model="formData.title"
                                                         code="titles"
                                                         :validate-error="errors[0]"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="name"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('name')">
                                    <b-form-input v-model="formData.name"
                                                  :placeholder="$t('name')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="surname"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('surname')">
                                    <b-form-input v-model="formData.surname"
                                                  :placeholder="$t('surname')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="email"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('email')">
                                    <b-form-input v-model="formData.email"
                                                  type="email"
                                                  :placeholder="$t('email')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="mobile_tel"
                                                maxlength="11"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('gsm_no')">
                                    <b-form-input v-model="formData.mobile_tel"
                                                  :placeholder="$t('gsm_no')"
                                                  v-mask="'+1 (###)-###-####'"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="mobile_tel_country_code "
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('mobile_tel_country_code')">
                                    <b-form-input type="text"
                                                  v-model="formData.mobile_tel_country_code "
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <div class="label-as-input-div">
                                <label>Staff ID</label>
                                <div>{{ formData.registration_number }}</div>
                            </div>

                            <ValidationProvider name="faculty_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox v-model="formData.faculty_code"
                                                       :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="department_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('department')">
                                    <department-selectbox v-model="formData.department_code"
                                                          :faculty_code="formData.faculty_code"
                                                          :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="program_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox v-model="formData.program_code"
                                                       :faculty_code="formData.faculty_code"
                                                       :department_code="formData.department_code"
                                                       :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <!--                            <ValidationProvider name="campus_id"-->
                            <!--                                                rules=""-->
                            <!--                                                v-slot="{ valid, errors }">-->
                            <!--                                <b-form-group :label="$t('campus')">-->
                            <!--                                    <campus-selectbox v-model="formData.campus_id"-->
                            <!--                                                      :state="errors[0] ? false : null"/>-->
                            <!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
                            <!--                                </b-form-group>-->
                            <!--                            </ValidationProvider>-->
                            <!--                            <ValidationProvider name="building_id"-->
                            <!--                                                rules=""-->
                            <!--                                                v-slot="{ valid, errors }">-->
                            <!--                                <b-form-group :label="$t('building')">-->
                            <!--                                    <building-selectbox v-model="formData.building_id"-->
                            <!--                                                        :campus_id="formData.campus_id"-->
                            <!--                                                        :validate-error="errors[0]"/>-->
                            <!--                                </b-form-group>-->
                            <!--                            </ValidationProvider>-->
                            <!--                            <ValidationProvider name="unit_id"-->
                            <!--                                                v-slot="{ valid, errors }">-->
                            <!--                                <b-form-group :label="$t('unit')">-->
                            <!--                                    <units-selectbox v-model="formData.unit_id"-->
                            <!--                                                     :multiple="false"-->
                            <!--                                                     :state="errors[0] ? false : null"/>-->
                            <!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
                            <!--                                </b-form-group>-->
                            <!--                            </ValidationProvider>-->
                            <ValidationProvider name="office"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('office')">
                                    <b-form-input type="text"
                                                  v-model="formData.office"
                                                  :placeholder="$t('office')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="contract_type"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('contract_type')">
                                    <parameter-selectbox code="contract_types"
                                                         type="english"
                                                         v-model="formData.contract_type"
                                                         :validate-error="errors[0]"
                                                         :state="errors[0] ? false : null"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="working_status"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('working_status')">
                                    <parameter-selectbox code="working_statuses"
                                                         type="english"
                                                         v-model="formData.working_status"
                                                         :validate-error="errors[0]"
                                                         :state="errors[0] ? false : null"/>

                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="start_date"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('start_date')">
                                    <select-date v-model="formData.start_date"
                                                 :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="end_date"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('end_date')">
                                    <select-date v-model="formData.end_date"
                                                 :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <b-row>
                                <b-col cols="12">
                                    <ValidationProvider name="photoFile" rules="" v-slot="{errors}">
                                        <b-form-group :label="$t('photo')">
                                            <b-input-group class="mb-0">
                                                <b-form-file v-model="photo"
                                                             :placeholder="$t('upload_file')"
                                                             :state="errors[0] ? false : null"
                                                             ref="fileInput"
                                                             @input="uploadSignature"
                                                />
                                                <b-input-group-append>
                                                    <b-button variant="outline-secondary"
                                                              @click="$refs.fileInput.$el.childNodes[0].click()">
                                                        {{ $t('browse') }}
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <img v-if="photoImg" :src="photoImg" alt="image" style="width:100%"
                                         class="border rounded"/>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="updateForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-tab>
                <b-tab :title="$t('identity_information')" ref="tab2">
                    <b-row class="mt-2">
                        <b-col cols="12" md="4">
                            <ValidationProvider name="father_name"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('father_name')">
                                    <b-form-input v-model="formData.father_name"
                                                  :placeholder="$t('father_name')"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="mother_name"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('mother_name')">
                                    <b-form-input type="text"
                                                  v-model="formData.mother_name"
                                                  :placeholder="$t('mother_name')"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="birthdate"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('date_of_birth')">
                                    <select-date v-model="formData.birthdate"
                                                 :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                            <!--                            <ValidationProvider name="birthdate"-->
                            <!--                                                rules=""-->
                            <!--                                                v-slot="{ valid, errors }">-->
                            <!--                                <b-form-group :label="$t('date_of_birth')">-->
                            <!--                                    <b-form-input type="date"-->
                            <!--                                                  v-model="formData.birthdate"-->
                            <!--                                                  :placeholder="$t('date_of_birth')"/>-->
                            <!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
                            <!--                                </b-form-group>-->
                            <!--                            </ValidationProvider>-->
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="nationality_code"
                                                rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('nationality')">
                                    <country-selectbox valueType="code"
                                                       v-model="formData.nationality_code"
                                                       :placeholder="$t('nationality')"
                                                       :state="errors[0] ? false : null"
                                                       :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="gender"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('gender')">
                                    <gender-selectbox type="english"
                                                      v-model="formData.gender"
                                                      :validate-error="errors[0]"
                                                      :state="errors[0] ? false : null"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="updateForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-tab>
            </ValidationObserver>
        </b-tabs>
    </div>
</template>

<script>
// Components
import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';
import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox';
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox';
import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox';
import UnitsSelectbox from '@/components/interactive-fields/UnitsSelectbox';

// Services
import StaffService from '@/services/StaffService';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import SelectDate from "@/components/interactive-fields/SelectDate";

export default {
    components: {
        SelectDate,
        AcademicYearsSelectbox,
        BuildingSelectbox,
        CampusSelectbox,
        ClassroomSelectbox,
        CountrySelectbox,
        CourseAutoComplete,
        DepartmentSelectbox,
        FacultySelectbox,
        GenderSelectbox,
        ParameterSelectbox,
        ProgramSelectbox,
        StaffAutoComplete,
        TrueFalseSelectbox,
        UnitsSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            tabIndex: 0,
            formData: {
                // Tab 1
                username: null,
                registration_number: null,
                title: null,
                name: null,
                surname: null,
                email: null,
                mobile_tel: null,

                faculty_code: null,
                department_code: null,
                program_code: null,
                campus_id: null,
                building_id: null,
                unit_id: null,
                office: null,

                contract_type: null,
                working_status: null,
                start_date: null,
                end_date: null,

                // Tab 2
                father_name: null,
                mother_name: null,
                birthdate: null,
                nationality_code: null,
                gender: null,
                mobile_tel_country_code: null,
            },
            photo: null,
            photoImg: null,
            userId: null,
            formLoading: false
        };
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        redirectTab() {
            let tab1 = this.$refs.tab1?.$el?.querySelector('.is-invalid,.box-border-color');
            let tab2 = this.$refs.tab2?.$el?.querySelector('.is-invalid,.box-border-color');


            if (tab1) return this.tabIndex = 0;
            if (tab2) return this.tabIndex = 1;
        },
        get(id) {
            StaffService.get(id)
                .then(response => {
                    let data = response.data.data;

                    this.formData.registration_number = data.registration_number
                    this.formData.title = data.title
                    if (data.person) {
                        this.formData.username = data.person.username
                        this.formData.name = data.person.name
                        this.formData.surname = data.person.surname
                        this.formData.email = data.person.email
                        this.formData.mobile_tel = data.person.mobile_tel
                        this.formData.mobile_tel_country_code = data.person.mobile_tel_country_code
                    }
                    this.formData.faculty_code = data.faculty_code
                    this.formData.department_code = data.department_code
                    this.formData.program_code = data.program_code
                    this.formData.campus_id = data.campus_id
                    this.formData.building_id = data.building_id
                    this.formData.unit_id = data.unit_id
                    this.formData.office = data.office


                    this.formData.contract_type = data.contract_type
                    this.formData.working_status = data.working_status
                    this.formData.start_date = data.start_date
                    this.formData.end_date = data.end_date

                    if (data.person) {
                        this.formData.father_name = data.person.father_name
                        this.formData.mother_name = data.person.mother_name
                        this.formData.birthdate = data.person.birthdate
                        this.formData.nationality_code = data.person.nationality_code
                        this.formData.gender = data.person.gender
                    }
                    this.userId = data.person.user_id

                    this.GetProfilePhoto()
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        // Section updateForm
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            this.redirectTab()
            if (isValid && this.formId) {
                this.formLoading = true;
                let formData = {
                    ...this.formData
                }
                formData.start_date = formData.start_date ? formData.start_date : null;
                formData.end_date = formData.end_date ? formData.end_date : null;
                formData.birthdate = formData.birthdate ? formData.birthdate : null;

                StaffService.update(this.formId, formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        if (e.status == '422') {
                            let errors = e.data.errors;
                            if (errors.username ||
                                errors.registration_number ||
                                errors.title ||
                                errors.name ||
                                errors.surname ||
                                errors.email ||
                                errors.mobile_tel ||
                                errors.faculty_code ||
                                errors.department_code ||
                                errors.program_code ||
                                errors.campus_id ||
                                errors.building_id ||
                                errors.unit_id ||
                                errors.office ||
                                errors.contract_type ||
                                errors.working_status ||
                                errors.start_date ||
                                errors.end_date
                            ) {
                                this.tabIndex = 0;
                            } else {
                                this.tabIndex = 1;
                            }
                        }
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    })
            } else {
                let errors = this.$refs.formModalValidate.errors;
                if (errors.username.length ||
                    errors.registration_number.length ||
                    errors.title.length ||
                    errors.name.length ||
                    errors.surname.length ||
                    errors.email.length ||
                    errors.mobile_tel.length ||
                    errors.faculty_code.length ||
                    errors.department_code.length ||
                    errors.program_code.length ||
                    errors.campus_id.length ||
                    errors.building_id.length ||
                    errors.unit_id.length ||
                    errors.office.length ||
                    errors.contract_type.length ||
                    errors.working_status.length ||
                    errors.start_date.length ||
                    errors.end_date.length
                ) {
                    this.tabIndex = 0;
                } else {
                    this.tabIndex = 1;
                }
            }
        },
        uploadSignature() {
            if (this.photo) {
                let formData = new FormData()
                formData.append('file', this.photo)
                StaffService.personUploadPhoto(formData, this.userId).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.GetProfilePhoto()
                }).catch(e => {
                    this.showErrors(e, this.$refs.formModalValidate)
                })
            }

        },
        GetProfilePhoto() {
            StaffService.personGetProfilePhoto(this.userId).then(response => {
                if (response.data.byteLength > 1) {
                    const blob = new Blob([response.data], { type: 'image/jpeg' });
                    this.photoImg = URL.createObjectURL(blob);
                }
            }).catch(e => {
                this.showErrors(e, null, true);
            });
        }
    }
}
</script>
